import clsx from 'clsx'
import Image from 'next/image'
import Link from 'next/link'
import React from 'react'
import { useMediaQuery } from 'react-responsive'
import { WifBannerType } from '../../types'

interface WifSmallBannerProps {
  id: string
  banners: WifBannerType[]
  width?: number
  height?: number
}

/**
 * Component for showing a series of small scrollable banners horizontally.
 * Pass in an array of banner objects WifBannerType[].
 * You can optionally provide widths and heights for the individual banners,
 * If not, the default size will be used (150px x 120px).
 *
 * @example
 * const banners: WifBannerType[] = [{
 *     {
 *       id: '0',
 *       imgSrc: 'https://via.placeholder.com/150x120.jpg',
 *       alt: 'New Arrival promo 10% off',
 *       title: 'New Arrival',
 *       href: '/collection/new-arrival'
 *     },
 *     {
 *       id: '1',
 *       imgSrc: 'https://via.placeholder.com/150x120.jpg',
 *       alt: 'Promo Spesial khusus pelanggan baru',
 *       title: 'Sale',
 *       href: '/collection/sale'
 *     },
 *     // …
 * }]
 **
 * return (
 *      <WifSmallBanners banners={banners} />
 * )
 *
 * @component
 */
const WifSmallBanners: React.FC<WifSmallBannerProps> = ({ id, banners }) => {
  const isMobile = useMediaQuery({ maxWidth: 768 })
  return (
    <div
      id={id}
      className={clsx(
        'flex flex-row items-center justify-between md:justify-center gap-4 bg-smoke-white py-8 px-4 md:px-[110px] md:py-20'
      )}
      data-test={`homepage-small-banners-${id}`}
    >
      {banners.map(({ id, imgSrc, alt, href, title }, i) => {
        return (
          <div
            data-test={`homepage-small-banners-banner-${i}`}
            key={id}
            className='cursor-pointer w-full md:w-fit rounded-full shadow-[0px_40px_30px_0px_rgba(0,0,0,0.1)]'
          >
            <Link href={href || '/'}>
              <div
                key={id}
                className='flex flex-row items-center bg-white shadow-primary/10 rounded-[30px] gap-3 border border-primary/50 py-2 px-5 md:py-5 md:px-10'
              >
                <div className='flex'>
                  <Image
                    src={imgSrc || '/icons/ic-img-placeholder.svg'}
                    width={isMobile ? 32 : 60}
                    height={isMobile ? 32 : 60}
                    // saved to show how to put image as background
                    // sizes='100vw'
                    // style={{ objectFit: 'cover', width: '100%', height: '100%' }}
                    alt={alt}
                    data-test={`homepage-small-banners-banner-image-${i}`}
                    priority
                  />
                </div>
                <div
                  className={'text-center text-sm md:text-lg font-bold uppercase'}
                  data-test={`homepage-small-banners-banner-text-${i}`}
                >
                  {title}
                </div>
              </div>
            </Link>
          </div>
        )
      })}
    </div>
  )
}

export default WifSmallBanners
