import Loading from '@/components/Loading'
import { PropsWithChildren } from 'react'
import { useMediaQuery } from 'react-responsive'
import { ProductVariantConnection } from 'shopify/api'
import { WifSlideType } from 'types'
import { useGetBannerQuery } from '../../cms/api'
import WifSlider from '../Slider'

interface HomePageBannerProps {}

const HomePageBanner: React.FC<PropsWithChildren<HomePageBannerProps>> = () => {
  const { loading, data } = useGetBannerQuery({
    context: {
      headers: {
        'X-Shopify-Storefront-Access-Token': process.env.NEXT_PUBLIC_CMS_GQL_TOKEN,
      },
    },
  })
  const isMobile = useMediaQuery({ maxWidth: 768 })
  if (loading) return <Loading />
  if (!data?.product) return null
  const { nodes } = data?.product?.variants as ProductVariantConnection
  const slides = nodes.map(({ image, selectedOptions }) => {
    return {
      id: image?.id,
      alt: image?.altText || '',
      imgSrc: image?.url,
      height: 137,
      width: 366,
      href: selectedOptions[0].value,
    } as WifSlideType
  })

  return (
    <div className='mt-4 md:mt-6'>
      <WifSlider
        id='homepage-banner'
        slides={slides}
        slidesPerView={isMobile ? 1.2 : 1.4}
        pagination
        gap={isMobile ? 10 : 30}
        autoPlay={10000}
        isMobile={isMobile}
        centered
      />
    </div>
  )
}

export default HomePageBanner
