const CrossSellBanner: React.FC<{
  id: string
  withCta?: boolean
  title: string
  description?: string
}> = ({ id, withCta = true, title, description }) => {
  const imageUrl = (id: string) => {
    if (id === 'maas-banner') return '/images/woman-working-with-sewing-machine.png'
    else return '/images/spool.png'
  }

  return (
    <section id={id}>
      <div
        className='relative overflow-hidden bg-no-repeat bg-contain justify-end bg-right min-h-fit md:min-h-[325px]'
        style={{ backgroundImage: `url(${imageUrl(id)}` }}
      >
        <div className='absolute -top-[1000px] right-0 md:right-[45%] aspect-square h-[2000px] md:rotate-[45deg] bg-[#d0deef] z-20'></div>

        <div className='text-wif-neutral-40 relative z-30 px-4 py-8 md:px-[110px] max-w-[400px] md:max-w-[600px] w-full min-h-fit flex flex-col md:min-h-[325px] items-start justify-center'>
          <h1 className='text-xl md:text-4xl font-bold mb-4'>{title}</h1>
          <p className='text-base md:text-lg mb-4'>{description}</p>
          {withCta && (
            <button className='border rounded-full border-wif-neutral-40 font-bold py-2 px-4'>
              Info Lebih Lanjut
            </button>
          )}
        </div>
      </div>
    </section>
  )
}

export default CrossSellBanner
