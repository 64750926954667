import clsx from 'clsx'
import Link from 'next/link'
import { PropsWithChildren } from 'react'
import WifButton from '../WifButton'

const CtaRow: React.FC<{}> = ({}) => {
  return (
    <SectionWrapper
      id='require-assistance'
      className={clsx(
        'flex-col items-start justify-center gap-10 bg-smoke-white',
        'md:justify-start',
        'lg:gap-6'
      )}
    >
      <div className='flex flex-col gap-5'>
        <SectionTitle
          text='Require Assistance?'
          className={clsx('text-primary lg:max-w-[900px]')}
        />

        <SectionDescription text='Collaborate with our team to ensure optimal production outcomes for your grand ideas.' />
      </div>

      <div
        className={clsx(
          'flex w-full max-w-[200px] flex-col justify-start gap-4 md:w-full md:max-w-full md:flex-row md:items-center'
        )}
      >
        <Link
          href={{ pathname: '' }}
          target='_blank'
          referrerPolicy='no-referrer'
          className='shrink-0'
        >
          <WifButton
            id='chat-with-us-cta-row'
            text='Chat With Us'
            type='primary'
            handleClick={() => {}}
            textTransform='uppercase'
          />
        </Link>

        <Link href={{ pathname: '' }} target='_parent'>
          <WifButton
            text='Register Now'
            id='start-production'
            handleClick={() => {}}
            textTransform='uppercase'
            border
            customStyle='!border-primary !bg-transparent !text-primary'
          />
        </Link>
      </div>
    </SectionWrapper>
  )
}

const SectionWrapper: React.FC<
  PropsWithChildren<{ id: string; separator?: boolean; className?: string; padded?: boolean }>
> = ({ children, id, separator = false, className = '', padded = true }) => {
  return (
    <section
      id={id}
      className={clsx(
        'flex',
        separator && 'border-t-2 border-white-smoke',
        padded ? 'px-4 py-8 md:px-[110px] md:py-20' : '',
        className
      )}
    >
      {children}
    </section>
  )
}

const SectionTitle: React.FC<{
  text: string
  className?: string
  ref?: React.RefObject<Element>
  primary?: boolean
  secondary?: boolean
}> = ({ text, className = '', ref, secondary, primary }) => {
  return (
    <h2
      className={clsx(
        'text-left text-2xl font-bold leading-tight md:text-3xl md:leading-normal lg:leading-[1.3]',
        primary ? 'text-wif-blue' : secondary ? 'text-white' : 'text-grey',
        className
      )}
    >
      {text}
    </h2>
  )
}

const SectionDescription: React.FC<{
  text: string
  className?: string
  primary?: boolean
  secondary?: boolean
}> = ({ text, className, primary = false }) => {
  return (
    <p
      className={clsx(
        'text-left text-base leading-[1.4] md:text-lg',
        primary ? 'text-primary' : 'text-grey',
        className
      )}
    >
      {text}
    </p>
  )
}

export default CtaRow
