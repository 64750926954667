import ProductCard from '@/components/ProductCard'
import clsx from 'clsx'
import SwiperCore, { Autoplay, Keyboard, Mousewheel, Pagination } from 'swiper'
import 'swiper/css'
import 'swiper/css/pagination'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Product } from '../../shopify/api'

SwiperCore.use([Keyboard, Mousewheel])

interface ProductCardSliderProps {
  id: string
  products: Product[]
  pagination?: boolean
  /**
   * in milliseconds.
   *
   * ex: value 5000 = 5s
   */
  autoPlay?: number
  slidesPerView?: number
  className?: string
  sliderClassName?: string
}

const ProductCardSlider: React.FC<ProductCardSliderProps> = ({
  id,
  products,
  pagination,
  autoPlay,
  slidesPerView = 1,
  className,
  sliderClassName,
}) => {
  const paginationConfig = {
    el: `.slider-pagination-${id}`,
    clickable: true,
    bulletClass: 'bg-wif-grey inline-block w-2 h-2 z-10 rounded-full mx-1',
    bulletActiveClass: 'bg-wif-orange',
  }

  return (
    <div
      id={id}
      data-test={'product-card-slider'}
      className={clsx([className, 'relative', 'z-0 px-4 -ml-4', 'md:px-0'])}
    >
      <Swiper
        key={id}
        data-test={id}
        modules={[Pagination, Autoplay]}
        spaceBetween={10}
        slidesPerView={slidesPerView}
        pagination={pagination ? paginationConfig : false}
        autoplay={autoPlay ? { delay: autoPlay } : false}
        scrollbar={{ hide: true }}
        keyboard={true}
        mousewheel={true}
        style={{ padding: '0 16px' }}
      >
        {products.map((product, index) => {
          const isMaaS = product.tags.some((value) => value.toLowerCase() === 'maas')
          // showing the product with minimum has 1 variant (exclude Sample)
          // MaaS has no variant, but only has 1 sample. So, for MaaS still need to be shown on the card
          const filteredProducts = product.variants.nodes.filter((variant) => {
            return isMaaS ? variant : !variant.title.toLowerCase().includes('sample')
          })
          if (filteredProducts.length <= 0) {
            return null
          } else {
            return (
              <SwiperSlide
                key={index}
                data-test={`slide-${index}`}
                className={clsx(sliderClassName, '!h-auto min-w-[150px]')}
              >
                <ProductCard id={index} product={product} />
              </SwiperSlide>
            )
          }
        })}
      </Swiper>
      {/* Move pagination to outside of <swiper /> */}
      {pagination && (
        <div
          className={clsx(`slider-pagination-${id}`, 'text-center')}
          data-test={`slider-pagination-${id}`}
        />
      )}
    </div>
  )
}

export default ProductCardSlider
