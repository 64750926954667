import clsx from 'clsx'
import { useTranslation } from 'next-i18next'
import Link from 'next/link'

const FooterTitleSection: React.FC<{ title: string }> = ({ title }) => {
  return <h5 className='text-sm font-bold uppercase md:text-lg'>{title}</h5>
}

const contactUs = [
  { logoUrl: '/icons/ic-mail.svg', alt: 'Envelop logo', text: 'admin@wifworld.com' },
  { logoUrl: '/icons/ic-phone.svg', alt: 'Phone logo', text: '+62 215 010 3933' },
  {
    logoUrl: '/icons/ic-location.svg',
    alt: 'Location logo',
    text: 'Jl. Panglima Polim VIII No.17, RT.6/RW.6, Melawai, Kec. Kby. Baru, Kota Jakarta Selatan, Daerah Khusus Ibukota Jakarta 12160',
  },
]

const services = [
  {
    url: '/maas',
    text: 'Word_Manufacture',
  },
  {
    url: 'https://kain.wifkain.com/',
    text: 'Word_Textile',
  },
  {
    url: '/finance',
    text: 'Word_Finance',
  },
]

const Footer: React.FC<{}> = ({}) => {
  const { t } = useTranslation()
  return (
    <footer
      className={clsx(
        'flex flex-col gap-4 bg-blue-grey px-4 py-8 text-white md:py-12',
        'md:grid md:px-[110px] md:grid-cols-3'
      )}
    >
      <div
        className={clsx(
          'flex flex-col items-start justify-center gap-2',
          'md:items-start md:justify-start md:grid-in-service'
        )}
      >
        <FooterTitleSection title={t('Word_Services')} />
        <ul className='flex w-full items-center justify-between md:flex-col md:items-start md:justify-center'>
          {services.map(({ url, text }: any) => (
            <li key={text}>
              <Link
                href={{ pathname: url }}
                target='_parent'
                className='flex py-2 text-xs uppercase md:text-base'
              >
                {t(text)}
              </Link>
            </li>
          ))}
        </ul>
      </div>

      <div
        className={clsx(
          'flex flex-col items-start justify-center gap-2',
          'md:items-start md:justify-start md:grid-in-contactus'
        )}
      >
        <FooterTitleSection title={t('Word_ContactUs')} />

        {contactUs.map(({ text, logoUrl, alt }: any) => (
          <div
            className='flex flex-col items-start justify-center md:flex-row md:justify-start md:gap-2 md:text-left'
            key={alt}
          >
            <span className='max-w-[320px] text-left text-xs md:text-base'>{t(text)}</span>
          </div>
        ))}
      </div>

      <div className='mt-2 flex items-center justify-between gap-3 md:mt-0 md:flex-col md:items-end md:justify-start md:gap-5'>
        <ul className='flex items-center gap-4 md:gap-6'>
          <li title='Whatsapp'>
            <Link
              href={{ pathname: 'https://wa.me/6281315357411' }}
              target='_blank'
              referrerPolicy='no-referrer'
            >
              <img src='/icons/wa.svg' className='h-5 w-5 md:h-7 md:w-7' alt='Whatsapp' />
            </Link>
          </li>
          <li title='Instagram'>
            <Link
              href={{ pathname: 'https://www.instagram.com/wifkain' }}
              target='_blank'
              referrerPolicy='no-referrer'
            >
              <img src='/icons/ig.svg' className='h-5 w-5 md:h-7 md:w-7' alt='Instagram' />
            </Link>
          </li>
          <li title='Linkedin'>
            <Link
              href={{ pathname: 'https://www.linkedin.com/company/wifworld' }}
              target='_blank'
              referrerPolicy='no-referrer'
            >
              <img src='/icons/in.svg' className='h-5 w-5 md:h-7 md:w-7' alt='Linkedin' />
            </Link>
          </li>
        </ul>
      </div>

      <div className='mt-2 items-center justify-center md:max-w-none md:text-sm md:grid-in-copyright'>
        <h5 className='text-xs md:text-sm'>
          &copy; Wifworld Pte Ltd | PT Wifkain Teknologi Indonesia 2024, all rights reserved
        </h5>
      </div>
    </footer>
  )
}

export default Footer
