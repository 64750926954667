import ProductCardSlider from '@/components/LimitedSaleTime/ProductCardSlider'
import Loading from '@/components/Loading'
import BannerSection from '@/components/PCP/BannerSection'
import clsx from 'clsx'
import { useTranslation } from 'next-i18next'
import Image from 'next/image'
import Link from 'next/link'
import React from 'react'
import { useMediaQuery } from 'react-responsive'
import { ClientContextType } from 'types'
import {
  Product,
  ProductCollectionSortKeys,
  useGetProductCollectionBySlugQuery,
} from '../../shopify/api'
import { getClientContext } from '../utilities/ClientContextUtility'

interface HomeCollectionProps {
  id: string
  collectionHandle: string
  showCollectionBanner?: boolean
  viewAllHref?: string
  sliderClassName?: string
  bannerWidth?: number
  bannerHeight?: number
  clientContextType?: ClientContextType
  icon?: string
}

const HomeCollectionSection: React.FC<HomeCollectionProps> = ({
  id,
  collectionHandle,
  showCollectionBanner = false,
  viewAllHref = `/collections/${collectionHandle}`,
  sliderClassName,
  bannerWidth,
  bannerHeight,
  clientContextType,
  icon,
}) => {
  const { t } = useTranslation('ready-stock')
  const context = getClientContext(clientContextType!)
  const isMobile = useMediaQuery({ maxWidth: 768 })
  const slidePerViewValue = isMobile ? 2.5 : 5.3

  const { loading, data, error } = useGetProductCollectionBySlugQuery({
    variables: {
      first: 10,
      slug: collectionHandle,
      sortKey: ProductCollectionSortKeys.Relevance,
    },
    context,
    fetchPolicy: 'no-cache',
  })

  if (loading) return <Loading />

  if (error) return null
  if (!data?.collection?.products) return null

  const { title, image: bannerImage } = data.collection
  const products = data.collection.products.edges.map((edge) => edge.node) as Product[]

  if (products.length === 0) return null

  return (
    <div className='flex flex-col gap-2 md:gap-4 mb-6 mt-6'>
      <div className='flex items-center justify-between gap-2 md:px-0 px-4 md:justify-start md:gap-16'>
        <div className='flex gap-4 items-center'>
          {icon && (
            <div className='relative w-8 h-8 md:w-12 md:h-12'>
              <Image src={icon} alt={id} fill style={{ objectFit: 'contain' }} />
            </div>
          )}
          <div className='font-quicksand font-bold uppercase'>
            {t(`common:${collectionHandle}`)}
          </div>
        </div>
        <div className={clsx('flex justify-end flex-nowrap gap-2 items-center cursor-pointer')}>
          <Link href={viewAllHref} className='flex items-center'>
            <span className='font-bold text-[14px] md:text-primary uppercase'>{t('view_all')}</span>
          </Link>
          <Image src={'/icons/ic-chevron-right-wc.svg'} width={12} height={12} alt='Open' />
        </div>
      </div>

      {showCollectionBanner && bannerImage && (
        <div className='mb-2 px-4'>
          <BannerSection
            imgSrc={bannerImage.url}
            alt={bannerImage.altText || 'Collection Banner'}
            id={`banner-image-${id}`}
            href={viewAllHref}
            width={bannerWidth}
            height={bannerHeight}
          />
        </div>
      )}

      <ProductCardSlider
        id={`product-card-slider-${id}`}
        products={products}
        slidesPerView={slidePerViewValue}
        sliderClassName={sliderClassName}
      />
    </div>
  )
}

export default HomeCollectionSection
