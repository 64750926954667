import clsx from 'clsx'
import Image from 'next/image'
import Link from 'next/link'
import SwiperCore, { Autoplay, Keyboard, Mousewheel, Navigation, Pagination } from 'swiper'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'

import { Swiper, SwiperSlide } from 'swiper/react'
import { WifSlideType } from 'types'

SwiperCore.use([Keyboard, Mousewheel])

interface WifSliderProps {
  id: string
  slides: WifSlideType[]
  pagination?: boolean
  paginationPosition?: 'below' | 'overlay'
  /**
   * in milliseconds.
   *
   * ex: value 5000 = 5s
   */
  autoPlay?: number
  gap?: number
  slidesPerView?: number
  rounded?: boolean
  outerPadding?: boolean
  isMobile?: boolean
  centered?: boolean
}

const WifSlider: React.FC<WifSliderProps> = ({
  id,
  slides,
  pagination,
  paginationPosition = 'below',
  autoPlay,
  gap = 0,
  slidesPerView = 1,
  rounded = true,
  outerPadding = true,
  isMobile = false,
  centered = false,
}) => {
  const paginationConfig = {
    el: `.slider-pagination-${id}`,
    clickable: true,
    bulletClass:
      'border-2 border-wif-orange inline-block w-2 h-2 z-10 rounded-full mx-1 cursor-pointer',
    bulletActiveClass: '!bg-wif-orange border-none',
  }
  const overrideSwiperStyle = outerPadding ? { padding: '0 16px 0 16px' } : {}

  return (
    <div id={id} className='relative'>
      <Swiper
        key={id}
        data-test={id}
        modules={[Pagination, Autoplay, Navigation]}
        spaceBetween={gap}
        slidesPerView={slidesPerView}
        pagination={pagination ? paginationConfig : false}
        autoplay={autoPlay ? { delay: autoPlay } : false}
        scrollbar={{ hide: true }}
        keyboard={true}
        mousewheel={true}
        style={overrideSwiperStyle}
        navigation={!isMobile}
        centeredSlides={centered}
        loop={true}
      >
        {slides.map(({ id, width, height, imgSrc, alt, href }, index) => {
          const image = (
            <Image
              className={clsx(rounded && 'rounded-lg')}
              src={imgSrc || '/icons/ic-img-placeholder.svg'}
              width={width}
              height={height}
              alt={alt}
              sizes='100vw'
              style={{ objectFit: 'cover', width: '100%', height: 'auto' }}
            />
          )
          return (
            <SwiperSlide key={id} data-test={`slide-${index}`}>
              <div className={clsx(rounded && 'rounded-lg', 'bg-wif-grey')}>
                {href ? <Link href={href || '/'}>{image}</Link> : <>{image}</>}
              </div>
            </SwiperSlide>
          )
        })}
      </Swiper>

      {pagination && (
        <div
          className={clsx(
            `slider-pagination-${id}`,
            'text-center',
            'relative',
            'pt-2',
            // When in overlay mode, put the pagination inside the slider
            paginationPosition === 'overlay' && ['-mt-8', 'z-10']
          )}
          data-test={`slider-pagination-${id}`}
        />
      )}
    </div>
  )
}

export default WifSlider
