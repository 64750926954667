import Loading from '@/components/Loading'
import WifSmallBanners from '@/components/SmallBanners/WifSmallBanners'
import { useGetSmallBannersQuery } from '../../cms/api'
import { ProductVariantConnection } from '../../shopify/api'
import { WifBannerType } from '../../types'

const HomePageSmallBanners = () => {
  const { loading, data } = useGetSmallBannersQuery({
    context: {
      headers: {
        'X-Shopify-Storefront-Access-Token': process.env.NEXT_PUBLIC_CMS_GQL_TOKEN,
      },
    },
  })
  if (loading) return <Loading />
  if (data?.product === null || !data?.product?.variants) return null
  const { nodes } = data?.product?.variants as ProductVariantConnection

  const banners = nodes.map(({ image, selectedOptions, sku }) => {
    return {
      id: image?.id,
      alt: image?.altText || '',
      imgSrc: image?.url,
      href: { pathname: selectedOptions[0].value },
      title: sku,
    } as WifBannerType
  })

  return <WifSmallBanners id='homepage-small-banners' banners={banners} width={150} height={80} />
}

export default HomePageSmallBanners
