import { getCategories } from '@/helpers/utilities'
import { useTranslation } from 'next-i18next'
import Image from 'next/image'
import { useRouter } from 'next/router'
import CategorySelectorItem from '../Explore/CategorySelectorItem'

const HomeCategoryList: React.FC<{}> = ({}) => {
  const { t } = useTranslation()
  const categories = getCategories()
  const router = useRouter()
  return (
    <div data-test='category-selector'>
      <div className='flex gap-4 items-center mb-[16px]'>
        <div className='relative w-8 h-8 md:w-12 md:h-12'>
          <Image
            src='/icons/wrapping.svg'
            alt='Wrapping icons'
            fill
            style={{ objectFit: 'contain' }}
          />
        </div>
        <h2 className='font-quicksand font-bold text-base uppercase'>
          {t('explore:Explore_Category_Title')}
        </h2>
      </div>
      <div className='flex gap-3 md:gap-4 whitespace-nowrap overflow-auto mr-4'>
        {categories.map(({ title, iconSrc, decodedQueryParam }, index) => {
          return (
            <CategorySelectorItem
              index={index}
              key={`${decodedQueryParam} ${index}`}
              title={title}
              active={false}
              iconSrc={iconSrc}
              onSelectCategory={() => router.push(`/explore?category=${decodedQueryParam}`)}
            />
          )
        })}
      </div>
    </div>
  )
}

export default HomeCategoryList
