import CrossSellBanner from '@/components/CrossSell/CrossSell'
import Footer from '@/components/Footer'
import HomeCategoryList from '@/components/HomePage/HomeCategoryList'
import HomeCollectionSection from '@/components/HomePage/HomeCollectionSection'
import HomePageBanner from '@/components/HomePage/HomePageBanner'
import HomePageSmallBanners from '@/components/HomePage/HomePageSmallBanners'
import TextileRightAction from '@/components/TextileRightAction'
import { WifNotification } from '@/components/WifNotification'
import CtaRow from '@/components/common/CtaRow'
import RootLayout from '@/layouts/RootLayout'
import { GetServerSideProps, InferGetServerSidePropsType } from 'next'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import Head from 'next/head'
import React, { useEffect, useState } from 'react'
import { useMediaQuery } from 'react-responsive'

const Home: React.FC<{ from: string }> = ({
  from,
}: InferGetServerSidePropsType<typeof getServerSideProps>) => {
  // const [limitedSaleData, setLimitedSaleData] = useState<DiscountAutomaticNode>()
  // const [showing, setShowing] = useState(false)
  // const [isMaasEnabled, setIsMaasEnabled] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(true)

  // useEffect(() => {
  //   axios
  //     .get('/api/campaign', {
  //       validateStatus: (status) => {
  //         return status >= 200 && status <= 300
  //       },
  //     })
  //     .then((resp) => {
  //       if (resp.status === 200) setLimitedSaleData(resp.data.data.automaticDiscountNode)
  //     })
  //     .catch(() => {
  //       notify({
  //         text: 'An error just happened. Please try refreshing.',
  //         type: 'error',
  //       })
  //     })

  //   setIsMaasEnabled(!!Number(process.env.NEXT_PUBLIC_MAAS_ENV_TOGGLE))
  //   setShowing(true)
  // }, [])

  // const renderCampaign = () => {
  //   const automaticBasic = limitedSaleData?.automaticDiscount as DiscountAutomaticBasic
  //   const endDateTime = new Date(automaticBasic?.endsAt)
  //   if (endDateTime > new Date() && automaticBasic?.status === 'ACTIVE')
  //     return <LimitedSaleTime campaignData={automaticBasic} />
  // }

  // if (!showing) {
  //   return null
  // }

  // need to handle hyrdration by doing this
  const isMobile = useMediaQuery({ maxWidth: 768 })
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false)
    }, 100)
  }, [])

  if (isLoading) return null

  if (typeof window === 'undefined') {
    return <></>
  } else {
    return (
      <RootLayout from={from} rightActions={<TextileRightAction />} withTabBar={isMobile}>
        <Head>
          <title>WifKain</title>
        </Head>

        <HomePageBanner />

        <div className='p-4 md:px-[110px] md:py-20'>
          <HomeCategoryList />
        </div>

        <HomePageSmallBanners />

        <CrossSellBanner
          id='maas-banner'
          title='Belum Menemukan Vendor Produksi yang Cocok?'
          description='Yuk bikin produk fashion-mu di Wifkain!'
        />

        <div className='md:px-[110px]'>
          <HomeCollectionSection
            id='ready-stock-home-collection'
            collectionHandle='ready-stockwif'
            icon='/icons/fabric.svg'
            sliderClassName='pb-2'
          />
        </div>

        <CrossSellBanner
          title='Made to Order'
          description='Solusi pemenuhan kebutuhan bahan baku kain sesuai dengan spesifikasi dan warna yang diinginkan'
          id='made-to-order-banner'
          withCta={false}
        />

        <div className='md:px-[110px]'>
          <HomeCollectionSection
            id='made-to-order-home-collection'
            collectionHandle='made-to-order'
            viewAllHref='/made-to-order'
            sliderClassName='pb-2'
            bannerHeight={107}
            clientContextType='mto'
            icon='/icons/bobbin.svg'
          />
        </div>

        <CtaRow />

        <Footer />

        <WifNotification />
      </RootLayout>
    )
  }
}

export const getServerSideProps: GetServerSideProps = async ({ locale, req }) => {
  return {
    props: {
      ...(await serverSideTranslations(locale!, [
        'common',
        'navbar',
        'limited-sale-time',
        'ready-stock',
        'pdp',
        'rfq',
        'service',
        'explore',
      ])),
      from: req.headers.referer || '',
    },
  }
}

export default Home
