import { ClientContextType } from 'types'

export const getClientContext = (context: ClientContextType | undefined) => {
  switch (context) {
    case 'maas':
      return {
        headers: {
          'X-Shopify-Storefront-Access-Token': process.env.NEXT_PUBLIC_MAAS_GQL_TOKEN,
        },
      }
    case 'mto':
      return {
        headers: {
          'X-Shopify-Storefront-Access-Token': process.env.NEXT_PUBLIC_MTO_GQL_TOKEN,
        },
      }
    default:
      return
  }
}
